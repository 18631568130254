import React, { useState } from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import ScrollUpButton from '../../Common/ScrollUpButton'
import SendSection from './SendSection'
import Title from '../../Common/Title'
import H1 from '../../Common/H1'
import { buildTestID } from '../../Utils/utils'
import { trackFormDownloaded } from '../../Global/Analytics'
import OnlineToolsRedirectModal from './OnlineToolsRedirectModal'
import { useAuthenticator } from '@aws-amplify/ui-react'
import CommonFormsCard, { SEND_FROM_COMPUTER, SEND_VIA_EMAIL, SEND_VIA_FAX, SEND_VIA_MAIL } from './CommonFormsCard'
import DownloadIcon from '../../assets/download-icon'
import EyeIconTwo from '../../assets/eye-icon-two'
import LandingPageHeading from "../../Components/LandingPageHeading";

const StyledVerificationFormsModern = Styled.div`
h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: left;
}

.card-wrapper {
  display: flex;
  margin-top: -68px;
  flex-direction: column;
  width: 100%;
}

.heading-title {
  font-size: 26px;
}

.underline {
  text-decoration: underline;
}

.vf_container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #F8FBFF;
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  padding: 20px;
  justify-content: center;

  &.white {
    background-color: #fff;
    padding: 0;
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
  }
  
  li > a {
    color: ${({ theme }) => theme.colors.primary};
  }
  
  .form_content {
    padding: 20px;
    width: 100%;
    
    .bold_instructions {
      font-weight: 500;
      color: var(--Black, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */

      a {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
    
    .form_instructions {

        .sub_heading {
          margin-bottom: 24px;
        }
        
        padding-bottom: 40px;

        p {
          line-height: 1.7;
          margin-bottom: 8px;
        }

        ol > li {
          margin-bottom: 10px;
        }
      }
    }

    .forms {
      margin-top: 40px;
      background-color: #FFF;
      position: relative;
      /* width: 100vw; */
      /* left: calc(-50vw + 50%); */
      /* padding: 20px; */

      .title {
        margin-bottom: 40px
      }

      .group {
          margin-bottom: 40px
        }  

      .form-wrapper {
        border-radius: 20px;
        box-shadow: 0px 1px 0px 0px #232830;

        :nth-child(odd) {
          background: linear-gradient(180deg, #475468 0%, #62728B 100%);
        }

        :nth-child(even) {
          background: linear-gradient(180deg, #2B3441 0%, #3D495C 100%);
        }
      }

      .form {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        /* padding: 10px 20px; */
        margin-bottom: 6px;
        /* align-items: center; */
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;

        /* &:hover {
          background: #E1E1E1;
          color: #00377a;
          border-radius: 20px;
        } */

        p {
          margin-bottom: 0;
        }

        h4, h5 {
          font-size: 20px;
          font-weight: medium;
          margin-bottom: 5px;
        }

        .form_text {
          max-width: 550px;
          margin-left: 12px;
        }

        .print {
          color: ${({ theme }) => theme.colors.white};
          font-size: 16px;
          font-weight: medium;
          flex-direction: row;
          display: flex;
          margin-top: 14px;

          svg {
            margin: 0 6px;
          }
        }

        .right {
          /* margin-left: auto; */
        }

        .form_icon {
          font-size: 40px;
          display: block;
        }

        a {
          display: flex;
          flex-direction: row;
          color: ${({ theme }) => theme.colors.white};
          align-items: center;

          :hover {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }


  .sub_list {
    list-style: circle;
    margin-left: 24px;
    li {
      font-weight: 500;
      padding-bottom: 4px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    .vf_container {
      flex-direction: column;

      .forms {

        .form {
          padding: 15px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 35px;
    }

    .vf_container {
      /* padding-left: 50%; */

      .form_content {
          padding: 20px;
          max-width: 1024px; 
        }

      .forms {
        /* padding: 20px; */

        .form {
          flex-direction: row;
          align-items: center;

          .form_text {
            max-width: 500px;
          }

          .print {
            flex-direction: row;
          }

          .right {
              margin-left: auto;
            }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .card-wrapper {
      flex-direction: row;
      justify-content: space-between;
    }

    .vf_container {
      /* padding-left: 50%; */

      .form_content {
          padding: 20px;
          max-width: 1024px; 
        }

      .forms {
        padding: 20px;

        .form {
          flex-direction: row;
          align-items: center;
          margin-bottom: 6px;
          padding: 12px;

          .print {
            flex-direction: row;
            margin-top: 0;
            justify-content: center;
            align-items: center;

            a {
              flex-direction: row;
            }
          }

          .right {
              margin-left: auto;
            }
        }
      }
    }
 }
`
const VerificationForms = ({ formGroups }) => {
  const [redirectModalOpen, setRedirectModalOpen] = useState('')
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const commonFormItems = [
    {
      title: 'Send from a computer or smart phone',
      description: 'If you have the completed, signed form as a file on your computer, you can send it to us now.',
      svg: SEND_FROM_COMPUTER,
      buttonProps: {
        text: 'Upload Now',
        linkTo: '/upload',
      },
      authStatus: authStatus
    },
    {
      title: 'Send via Email',
      description: 'Send by using the email on the form',
      svg: SEND_VIA_EMAIL
    },
    {
      title: 'Send via Fax',
      description: 'Fax your form to the fax number on the form',
      svg: SEND_VIA_FAX
    },
    {
      title: 'Send via Mail',
      description: 'Send by mail using the address on the form',
      svg: SEND_VIA_MAIL
    }
  ]

  return (
    <>
      <Title>Common Forms</Title>
      <StyledVerificationFormsModern>
        <main id="main" tabIndex="0">
          <Container>
            <div className='card-wrapper'>
              {
                commonFormItems.map((item, index) => (
                  <CommonFormsCard title={item.title} description={item.description} key={index} svg={item.svg} buttonProps={item.buttonProps} authStatus={authStatus} />
                ))
              }
            </div>
            <div className="vf_container">
              <div className="form_content">
                <div className="form_instructions">
                  <h1>How to send us a form:</h1>
                  <p className="sub_heading">
                    Periodically throughout the lifetime of your loan,
                    you will need to send us documentation such as
                    verification of occupancy, and repair invoices.
                  </p>
                  <p>
                    To send us a document, follow these instructions:
                  </p>
                  <ol>
                    <li>
                      <p className="bold_instructions">
                        Download the form <span style={{ color: "#C45835" }}>BEFORE</span> filling it out
                        (otherwise, your data won’t save)
                      </p>
                    </li>
                    <li>
                      <p className="bold_instructions">
                        Open the form you downloaded and complete it.
                        If you have trouble opening the form, you may
                        need to install Adobe Reader which can be
                        downloaded free of cost{' '}
                        <a href="https://get.adobe.com/reader/">
                          here
                        </a>
                        .
                      </p>
                    </li>
                    <li>
                      <p className="bold_instructions">
                      Print and sign the form in pen, or you can use Adobe Acrobat to sign the form electronically.
                      </p>
                    </li>

                    <li>
                      <p className="bold_instructions">
                        Submit the completed and signed form via the
                        method of your choice:
                      </p>
                    </li>
                  </ol>
                  <ul className="sub_list">
                    <li>
                      Electronically sign the form (or print, sign and scan) and upload it via this website.
                    </li>
                    <li>
                      Scan and email it to the email address on the
                      form
                    </li>
                    <li>Fax it to the fax number on the form</li>
                    <li>Mail it to the address on the form</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="vf_container white">
              <div className="form_content">
                <div className="forms">
                  <div style={{ marginBottom: "40px" }}>
                    <LandingPageHeading title="Common Forms" />
                  </div>
                  {formGroups.map((formGroup) => {
                    const { group, forms } = formGroup
                    return (
                      <div className="group" key={group}>
                        <h3>{group}</h3>
                        {forms.map((form) => {
                          const { name, description, url, onlineToolEligible } = form
                          if (!name.includes('Electronic Funds Transfer')) {
                            return (
                              <div
                                key={form.name}
                                data-testid={buildTestID(
                                  form.name,
                                  '/common_forms',
                                )}
                                className="form-wrapper"
                              >
                                {onlineToolEligible ? (
                                  <>
                                    <OnlineToolsRedirectModal
                                      formName={name}
                                      formGroup={group}
                                      dowloadFormLink={url}
                                      show={
                                        redirectModalOpen ===
                                        onlineToolEligible.url
                                      }
                                      closeScreen={() =>
                                        setRedirectModalOpen('')
                                      }
                                      {...onlineToolEligible}
                                    />
                                    <div
                                      className="form"
                                      onClick={() => {
                                        setRedirectModalOpen(
                                          onlineToolEligible.url,
                                        )
                                      }}
                                    >
                                      <div className="form_text">
                                        <h4>{name}</h4>
                                        {description && <p>{description}</p>}
                                      </div>
                                      <div className="print right">
                                        <a
                                          href={url}
                                          download
                                          rel="noreferrer"
                                          className="form"
                                          onClick={() => {
                                            trackFormDownloaded(name, group)
                                          }}
                                        >
                                          <DownloadIcon aria-label="download. open. in. new. tab." />
                                          Download
                                        </a>
                                        <a href={url}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="form"
                                          onClick={() => {
                                            trackFormDownloaded(name, group)
                                          }}>
                                          <span className='sr-only'>open in new tab</span>
                                          <EyeIconTwo />
                                          Preview
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="form">
                                    <div className="form_text">
                                      <h4>{form.name}</h4>
                                      {form.description && <p>{form.description}</p>}
                                    </div>
                                    <div className="print right">
                                      <a
                                        href={url}
                                        download
                                        rel="noreferrer"
                                        className="form"
                                        onClick={() => {
                                          trackFormDownloaded(name, group)
                                        }}
                                      >
                                        <DownloadIcon aria-label="download. open. in. new. tab." />
                                        Download
                                      </a>
                                      <a href={url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="form"
                                        onClick={() => {
                                          trackFormDownloaded(name, group)
                                        }}>
                                        <span className='sr-only'>open in new tab</span>
                                        <EyeIconTwo />
                                        Preview
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )
                          } else {
                            if (authStatus === 'authenticated') {
                              return (
                                <div
                                  key={form.name}
                                  data-testid={buildTestID(
                                    form.name,
                                    '/common_forms',
                                  )}
                                  className="form-wrapper"
                                >
                                  {onlineToolEligible ? (
                                    <>
                                      <OnlineToolsRedirectModal
                                        formName={name}
                                        formGroup={group}
                                        dowloadFormLink={url}
                                        show={
                                          redirectModalOpen ===
                                          onlineToolEligible.url
                                        }
                                        closeScreen={() =>
                                          setRedirectModalOpen('')
                                        }
                                        {...onlineToolEligible}
                                      />
                                      <div
                                        className="form"
                                        onClick={() => {
                                          setRedirectModalOpen(
                                            onlineToolEligible.url,
                                          )
                                        }}
                                      >
                                        <div className="form_text">
                                          <h4>{name}</h4>
                                          {description && <p>{description}</p>}
                                        </div>
                                        <div className="print right">
                                          <DownloadIcon aria-label="download. open. in. new. tab." />
                                          Download
                                          <span className='sr-only'>open in new tab</span>
                                          <EyeIconTwo />
                                          Preview
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="form">
                                      <div className="form_text">
                                        <h4>{form.name}</h4>
                                        {form.description && <p>{form.description}</p>}
                                      </div>
                                      <div className="print right">
                                        <a
                                          href={url}
                                          download
                                          rel="noreferrer"
                                          className="form"
                                          onClick={() => {
                                            trackFormDownloaded(name, group)
                                          }}
                                        >
                                          <DownloadIcon aria-label="download. open. in. new. tab." />
                                          Download
                                        </a>
                                        <a href={url}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="form"
                                          onClick={() => {
                                            trackFormDownloaded(name, group)
                                          }}>
                                          <span className='sr-only'>open in new tab</span>
                                          <EyeIconTwo />
                                          Preview
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            }
                          }
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Container>
          <ScrollUpButton />
        </main>
      </StyledVerificationFormsModern>
    </>
  )
}

export default VerificationForms

VerificationForms.propTypes = {
  formGroups: PropTypes.array.isRequired,
}
