import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
    stroke: ${({ theme, color }) => theme.colors[color]};
  }
`

const RegistrationHelpIcon = ({ color = 'primaryDark' }) => {
    return (
        <StyledSVG
             width="100" height="30" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="12.5" y="29.5" width="7" height="3" fill="#B6CEDB" stroke="#8A9FAA"/>
<path d="M14 33H18V34C18 34.5523 17.5523 35 17 35H15C14.4477 35 14 34.5523 14 34V33Z" fill="#8A9FAA"/>
<mask id="path-3-inside-1_1126_3604" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 24.1679C23.5318 22.6248 26 19.1006 26 15C26 9.47715 21.5228 5 16 5C10.4772 5 6 9.47715 6 15C6 19.1006 8.46819 22.6248 12 24.1679V28H20V24.1679Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 24.1679C23.5318 22.6248 26 19.1006 26 15C26 9.47715 21.5228 5 16 5C10.4772 5 6 9.47715 6 15C6 19.1006 8.46819 22.6248 12 24.1679V28H20V24.1679Z" fill="#F5EA8B"/>
<path d="M20 24.1679L19.5996 23.2516L19 23.5135V24.1679H20ZM12 24.1679H13V23.5135L12.4004 23.2516L12 24.1679ZM12 28H11V29H12V28ZM20 28V29H21V28H20ZM25 15C25 18.689 22.7803 21.8618 19.5996 23.2516L20.4004 25.0843C24.2833 23.3878 27 19.5123 27 15H25ZM16 6C20.9706 6 25 10.0294 25 15H27C27 8.92487 22.0751 4 16 4V6ZM7 15C7 10.0294 11.0294 6 16 6V4C9.92487 4 5 8.92487 5 15H7ZM12.4004 23.2516C9.21965 21.8618 7 18.689 7 15H5C5 19.5123 7.71673 23.3878 11.5996 25.0843L12.4004 23.2516ZM13 28V24.1679H11V28H13ZM20 27H12V29H20V27ZM19 24.1679V28H21V24.1679H19Z" fill="#AFA33C" mask="url(#path-3-inside-1_1126_3604)"/>
<rect x="14" y="18" width="1" height="9" fill="#AFA33C"/>
<rect x="17" y="18" width="1" height="9" fill="#AFA33C"/>
<rect x="14" y="18" width="4" height="1" fill="#AFA33C"/>
<rect x="12.0664" y="15.5195" width="1" height="4" transform="rotate(-30 12.0664 15.5195)" fill="#AFA33C"/>
<rect x="19.0664" y="15.0156" width="1" height="4" transform="rotate(30 19.0664 15.0156)" fill="#AFA33C"/>
<rect x="28" y="15" width="4" height="1" rx="0.5" fill="#AFA33C"/>
<rect x="26.1523" y="9" width="4" height="1" rx="0.5" transform="rotate(-30 26.1523 9)" fill="#AFA33C"/>
<rect x="1.80469" y="7.00391" width="4" height="1" rx="0.5" transform="rotate(30 1.80469 7.00391)" fill="#AFA33C"/>
<rect x="8.43359" y="0.152344" width="4" height="1" rx="0.5" transform="rotate(60 8.43359 0.152344)" fill="#AFA33C"/>
<rect x="23.4336" y="0.652344" width="4" height="1" rx="0.5" transform="rotate(120 23.4336 0.652344)" fill="#AFA33C"/>
<rect y="15" width="4" height="1" rx="0.5" fill="#AFA33C"/>


        </StyledSVG>
    )
}

export default RegistrationHelpIcon
