import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Title from '../../Common/Title'
import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import InfoCard from '../../Common/InfoCard.js'
import PrivacyPolicyIcon from '../../assets/privacy-policy-icon'
import { extractLoan } from '../../Utils/apiExtractors'
import { useApiContext } from '../../Hooks/useApiContext'
import { retrieveAnnualPrivacyNotice } from '../../services/api'
import { Spinner } from 'react-bootstrap'

const StyledAnnualPrivacyNotice = styled.div`
min-height: 80vh;

.mt-60 {
  margin-top: 60px;
}

.info_cards {
  margin: 0 0 0 12px;
  padding: 32px 0 0 0;

  .break_line {
    display: none;
  }
}
`

const AnnualPrivacyNotice = () => {
  const { loan: apiLoan } = useApiContext()
  const { data: loanData } = apiLoan
  const [notice, setNotice] = useState(undefined)
  const loanResponse = extractLoan(loanData)

  const clientName = loanResponse?.legalEntity?.legalEntity[0].servicer?.[0]?.clientName


  useEffect(() => {
    async function getNotice() {
      let response = null
      try {
        response = await retrieveAnnualPrivacyNotice()
      } catch (err) {
        console.log(err)
      } finally {
        const notices = response?.data?.annualPrivacyNotices?.annualPrivacyNotices
        let file = notices && notices.length ? notices[0].policyName : null
        if (file) {
          if (!file.endsWith('.pdf'))
            file = `${file}.pdf`
          setNotice(`./privacy-policies-2024/${file}`)
        } else {
          setNotice('')
        }
      }
    }

    getNotice()

  }, [])

  return (
    <>
      <Title>Annual Privacy Notice</Title>
      <StyleGuide>
        <StyledAnnualPrivacyNotice>
          <Grid>
            <div className='mt-60'>
              {
                <InfoCard
                  smallScreenStyles
                  SVG={PrivacyPolicyIcon}
                  title="Annual Privacy Notice"
                  paragraph={
                    notice === undefined ? (<Spinner
                      data-testid="spinner"
                      animation="border"
                      role="status"
                      size="sm"
                      className="spinner-icon"
                    />) : (notice.length ? (
                      <>
                        <a href={notice} target="_blank" rel="noreferrer">View Annual Privacy Notice</a>
                      </>
                    ) : (
                      <>
                        Your Annual Privacy Notice was provided with your Welcome Letter and during your HECM origination process.
                      </>
                    ))
                  }
                />
              }
            </div>
          </Grid>
        </StyledAnnualPrivacyNotice>
      </StyleGuide>
    </>
  )
}

export default AnnualPrivacyNotice
