import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const IncomeTaxes1098Forms = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.IncomeTaxes1098, title)
  })

  return (
    <>
      <h3 ref={title} id="income-taxes-1098-forms" tabIndex="-1">
        Income Taxes and 1098 Forms
      </h3>
      <p>
        <strong>
          NOTE: The following information is general in nature
          regarding the income tax implications of a reverse mortgage.
          It is not intended to be interpreted as tax advice. If you
          have specific tax- related questions about your reverse
          mortgage, please consult with a tax professional.
        </strong>
      </p>

      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Is the money I receive from my reverse mortgage taxable?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              In general, your reverse mortgage is a loan and
              therefore, not considered income. However, we cannot
              speak to your personal tax liability. Please consult
              with a tax professional.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is a 1098 form?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              A 1098 form is filed with the Internal Revenue Service
              (IRS) detailing the amount of interest and
              mortgage-related expenses paid on a mortgage during the
              tax year. A mortgage lender{' '}
              <strong>
                <span className="underline">may</span>
              </strong>{' '}
              be required, by the IRS, to provide this form to
              borrowers in some instances.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              Will I receive a 1098 form for my mortgage interest
              and/or mortgage insurance premium (“MIP”)?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              If you make prepayments of MIP and/or accrued interest to your loan in excess of $600 in any given year, we will generate a Form 1098, which is a statement of interest and MIP paid. Consult your loan documents for how prepayments are applied; however, generally prepaid funds are applied first to MIP, then to servicing fees (if applicable), then to accrued interest.<br />
              <br />
              If you <strong> {' '} <em>do not</em> </strong>{' '} make prepayments to the loan (you are not required to make prepayments to a reverse mortgage), you will not receive a Form 1098.<br/><br/>
              After death, if loan is paid off by heirs, a 1098 is issued in name of the Estate with borrower’s social security number.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              When is a 1099 form sent?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <p>
                <strong>1099A</strong> – Unpaid balance on abandoned
                properties and properties deeded to lender in lieu of
                foreclosure (DIL) – If a foreclosure sale is held a
                1099A is generated and box 1 is populated with the
                foreclosure sale date.
              </p>
              <p>
                <strong>1099C</strong> - Forgiveness of debt – If the
                cancellation of debt due to a foreclosure sale or
                short sale is $600 or greater a 1099C is generated.
              </p>
              <p>
                <strong>1099Int</strong> - Interest income to borrower
                through insurance loss draft – When a Borrower has an
                insurance claim over $10,000 these funds may be held
                by the mortgage loan servicer while the home is being
                repaired; however, any interest on those funds is
                considered income.
              </p>
              <p>
                <strong> 1099Misc</strong> - Reports payments
                disbursed to non-incorporated contractors (1099 Misc
                statements are issued to contractors who receive
                payments from the mortgage servicer)
              </p>
              <p>
                NOTE: If circumstances require a 1099A and 1099C to be
                issued in the same calendar year, the IRS allows the
                lender to combine information on form 1099C (therefore
                no 1099A will be issued).
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              When will I receive my 1098/1099?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              Under federal law, if we are required to send you a
              1098/1099 form, it will be mailed to you by January 31st
              of the year following the tax year for which the form is
              required.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default IncomeTaxes1098Forms
