import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
    stroke: ${({ theme, color }) => theme.colors[color]};
  }
`

const PasswordErrorIcon = ({ color = 'primaryDark' }) => {
    return (
        <StyledSVG
         width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7" cy="7" r="7" fill="#FF5050"/>
        <rect x="3" y="6" width="8" height="2" fill="white"/>
        


        </StyledSVG>
    )
}

export default PasswordErrorIcon
