import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
    stroke: ${({ theme, color }) => theme.colors[color]};
  }
`

const VectorIcon = ({ color = 'primaryDark' }) => {
    return (
        <StyledSVG
        width="12" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0C2.68874 0 0 2.68874 0 6C0 9.31126 2.68874 12 6 12C9.31126 12 12 9.31126 12 6C12 2.68874 9.31126 0 6 0ZM6 9.84106C5.60265 9.84106 5.28477 9.52318 5.28477 9.12583C5.28477 8.72848 5.60265 8.41059 6 8.41059C6.39735 8.41059 6.71523 8.72848 6.71523 9.12583C6.71523 9.50993 6.39735 9.84106 6 9.84106ZM6 7.68212C5.4702 7.68212 5.04636 3.65563 5.04636 3.12583C5.04636 2.59603 5.4702 2.15894 6 2.15894C6.5298 2.15894 6.95364 2.58278 6.95364 3.11258C6.95364 3.64238 6.5298 7.68212 6 7.68212Z" fill="#FF5050"/>
        </StyledSVG>
    )
}

export default VectorIcon
