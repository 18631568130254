import React from 'react'
import { Route } from 'react-router-dom'

import MyLoanContainer from '../Containers/MyLoanContainer'
import CommonForms from '../Containers/CommonFormsContainer'
import CommonQuestions from '../Components/CommonQuestions'
import Contact from '../Components/Contact'
import PrivacyPolicy from '../Components/PrivacyPolicy'
import TermsConditions from '../Components/TermsAndConditions'
import LandingPage from '../Containers/LandingPageContainer'
import Upload from '../Components/Upload/index'
import ScheduleOfFees from '../Components/ScheduleOfFees'
import Licenses from '../Components/Licenses'
import AccessibilityStatement from '../Components/AccessibilityStatement'
import AdvanceRequestContainer from '../Containers/AdvanceRequestContainer'
import MyAdvanceRequestsContainer from '../Containers/MyAdvanceRequestsContainer'
import PayoffQuote from '../Containers/PayoffQuoteContainer'
import ImportantDisclosures from '../Components/ImportantDisclosures'
import AccountSettings from '../Containers/AccountSettings'
import FinishYourAccountContainer from '../Containers/FinishYourAccountContainer'
import Transactions from '../Components/Transactions/index'
import StatementsAndDocuments from '../Components/StatementsAndDocuments/index'
import AnnualPrivacyNotice from '../Components/AnnualPrivacyNotice/index'
import Claim from '../Components/Claim'
import ESignContainer from '../Containers/ESignContainer'
import HelpfulResourcesContainer from '../Containers/HelpfulResourcesContainer'
import EnableMultifactorContainer from '../Containers/EnableMultifactorContainer'
import PaymentContainer from '../Containers/PaymentContainer'
import BadRequestPage from '../Components/BadRequestPage'

export const RouteList = [
  { path: '/', component: () => <LandingPage /> },
  { path: '/register', component: () => <LandingPage /> },
  { path: '/register/failed', component: () => <BadRequestPage /> },
  { path: '/paperless', component: () => <LandingPage /> },
  {
    path: '/advance-request',
    component: () => <AdvanceRequestContainer />,
  },
  {
    path: '/transactions',
    component: () => <Transactions />,
  },
  {
    path: '/statements-and-documents',
    component: () => <StatementsAndDocuments />,
  },
  {
    path: '/annual-privacy-notice',
    component: () => <AnnualPrivacyNotice />,
  },
  {
    path: '/my-advance-requests',
    component: () => <MyAdvanceRequestsContainer />,
  },
  {
    path: '/payoff-quote',
    component: () => <PayoffQuote />,
  },
  {
    path: '/my-loan',
    component: () => <MyLoanContainer />,
  },
  {
    path: '/esign',
    component: () => <ESignContainer />
  },
  {
    path: '/enable-multifactor',
    component: () => <EnableMultifactorContainer />
  },
  {
    path: '/helpful-resources',
    component: () => <HelpfulResourcesContainer />
  },
  { path: '/common-questions', component: () => <CommonQuestions /> },
  { path: '/contact', component: () => <Contact /> },
  { path: '/common-forms', component: () => <CommonForms /> },
  { path: '/account-settings', component: () => <AccountSettings /> },
  { path: '/finish-your-account', component: () => <FinishYourAccountContainer /> },
  { path: '/upload', component: () => <Upload /> },
  { path: '/privacy-policy', component: () => <PrivacyPolicy /> },
  { path: '/terms-conditions', component: () => <TermsConditions /> },
  {
    path: '/schedule-of-borrower-fees',
    component: () => <ScheduleOfFees />,
  },
  { path: '/licenses', component: () => <Licenses /> },
  {
    path: '/important-disclosures',
    component: () => <ImportantDisclosures />,
  },
  {
    path: '/claims',
    component: () => <Claim />,
  },
  {
    path: '/accessibility-statement',
    component: () => <AccessibilityStatement />,
  },
  {
    path: '/payment',
    component: () => <PaymentContainer />
  }
]

export const Routes = () => {
  return RouteList.map((x) => {
    const { path, component } = x
    return (
      <Route key={path} exact path={path} component={component} />
    )
  })
}