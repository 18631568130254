import * as React from "react";
import styled from "styled-components";

const PaymentTileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      flex-direction: row;
    }
`

const Section = styled.section`
  border-radius: 20px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 20px 0;
  height: 100%;
  padding: 40px 24px;

  a {
    text-decoration: underline;
  }


  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    max-width: 520px;
  }
`;

const Header = styled.h2`
  color: var(--Black, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 120% */

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Detail = styled.p`
  color: var(--Gray-00, #2d3032);
  margin-top: 32px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PaymentTiles = () => {
  return (
    <PaymentTileWrapper>
      <Section>
        <Header>Loan Balance:</Header>
        <Detail>
          If your current loan balance is $16,000 or less, or you want to make a payment over $15,000, you may still make a voluntary prepayment by check, money order or wire transfer. Please complete the Partial Prepayment Form available here <a href="./forms/2024/Partial_Prepayment_Form.pdf"> Prepayment Form </a>{' '} and mail the form and your payment to the address on the form.
        </Detail>
      </Section>
      <Section>
        <Header>Repayments:</Header>
        <Detail>
          If you are on an approved Repayment Plan Agreement for repayment of property charges, you must pay at least the amount of your monthly payment by the due date, as shown in your approved Repayment Plan Agreement. Partial monthly payments cannot be accepted.
        </Detail>
      </Section>
      <Section>
        <Header>Payment Options:</Header>
        <Detail>
          Payments may be made from a checking, business checking or savings account. Payments cannot be made with a credit or debit card. Have your loan number, property zip code and banking information (ABA routing number and account number) available.
        </Detail>
      </Section>
      <Section>
        <Header>Payment Effective Date:</Header>
        <Detail>
          Payments received after 5:00 pm ET, or on a banking holiday, will be applied on the next business day.
        </Detail>
      </Section>
      <Section>
        <Header>No Cancellation:</Header>
        <Detail>
          Electronic payments online or via the telephone may not be cancelled once submitted.
        </Detail>
      </Section>
      <Section>
        <Header>Payment Method:</Header>
        <Detail>
          You may make a one-time payment, or you may register on the payment website if you wish to save your payment information for use next time. You have the ability to make one payment per day on any loan not in foreclosure status.
        </Detail>
      </Section>
      <Section>
        <Header>Loans in Foreclosure Status:</Header>
        <Detail>
          If your loan is in foreclosure and you wish to cure a default for property charges, you must pay the entire default balance with certified or wired funds to stop foreclosure. You will not be able to pay online or via telephone. A Cashier’s Check may be sent to the address shown above, or if you prefer to send wired funds, call the number on your statement to obtain our wiring instructions.
        </Detail>
      </Section>
      <Section>
        <Header>Line of Credit Advances:</Header>
        <Detail>
          If you have a line of credit and you make a voluntary prepayment to your loan, you may not be able to redraw those funds for up to 10 business days. Additionally, the ability to redraw prepayments can vary by loan type. Please refer to the terms of your Note and Loan Agreement.
        </Detail>
      </Section>
      <Section>
        <Header>Payment in Full:</Header>
        <Detail>
          This service may not be used to pay off your loan in full. If you wish to pay off your loan in full, you may obtain or request a payoff quote by logging into or creating a portal account <a href="/register">here</a> or follow the instructions on this <a href="./forms/2024/Request_for_Reverse_Mortgage_Payoff_Quote.pdf" download rel="noreferrer">Payoff Quote Request Form</a>.<br /><br />
          NOTE:&nbsp;<i>The outstanding balance shown on your monthly statement is NOT sufficient to pay your loan off in full</i>.
        </Detail>
      </Section>
    </PaymentTileWrapper>
  );
}

export default PaymentTiles;