import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react';

import Title from '../Common/Title'
import StyleGuide from '../Global/StyleGuide'
import Grid from '../Global/Grid'
import PiggyBankIcon from '../assets/piggy-bank-icon.js'
import InfoCard from '../Common/InfoCard'
import useWindowSize from '../Hooks/useWindowSize'
import { useApiContext } from '../Hooks/useApiContext'
import { extractServicer } from '../Utils/apiExtractors'
import { formatPhoneNumber, isValidPhoneNumber } from '../Utils/utils'
import NoticeOfErrorCard from '../Common/NoticeOfErrorCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import CyberCrimeWarning from './CyberCrimeWarning.js';
import CybercrimeWarningDesktop from './CybercrimeWarningDesktop.js';

const StyledContactModern = styled.div`
  padding: 0px 0 80px 0;
  .background-image{
     background: url('/assets/background-contact.jpg') no-repeat center center;
      background-size: 100% 351px;
      height: 351px;
      border-bottom-left-radius: 38%;
      border-bottom-right-radius: 38%;
      margin-bottom:25px;
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    .background-image{
      background: url('/assets/background-contact-tablet.jpg') no-repeat center center;
      border-bottom-left-radius: 30%;
      border-bottom-right-radius: 30%;
      background-size: 100% 396px;
      margin-bottom:20px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .background-image{
      background: url('/assets/background-contact-mobile.jpg') no-repeat center center;
      border-bottom-left-radius: 20%;
      border-bottom-right-radius: 20%;
      background-size: 100% 300px;
      height:300px;
    }
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 48px;
    color: var(--Black, #000);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 112.5% */
  }

  .heading-6,
  p {
    margin: 0;
    color: var(--Black, #000);
    /* font-family: Lato; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }

  h2 {
    &.heading-6 {
      color: var(--Black, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 125% */
    }

    color: var(--Black, #000);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
  }

  .subtitle {
    margin-top: 10px;
  }

  .subtitle-2,
  .date_time {
    margin-top: 10px;
  }

  .contact_details {

    .contact-wrapper {
      border-radius: 20px;
      background: var(--White, #FFF);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
      padding: 21px 10px;
      margin-bottom: 20px;

      .contact_block {
        /* display: flex;
        margin: 0 0 30px 0; */

        .contact_description {
          /* width: 80px; */

          h6:nth-child(2) {
            margin-top: 10px;
          }

          /* min-width: 200px; */
        }

        .contact_info {
          /* margin: 0 0 0 15px;
          overflow-wrap: anywhere; */
          
          a {
            color: var(--Royal-Blue, #074EE8);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
            text-decoration-line: underline;
          }

          h6:nth-child(2) {
            margin-top: 12px;
          }

          a {
            text-transform: initial;
          }

          .note {
            color: var(--Black, #000);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
    }

  }

  .phone_web_link {
    text-decoration: none;
    font-weight: 700;
    text-transform: lowercase;
    word-break: break-word;
  }

  .important-text {
    border-radius: 20px;
    border: 1px solid var(--Light-Blue-2, #C5DCFB);
    background: var(--Light-Blue, #F8FBFF);
    padding: 30px 50px;
    margin-bottom: 20px;
    color: var(--Black, #000);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
  }

  a {
    text-decoration: none;
  }

  .cybercrime {
    border-radius: 20px;
    border: 1px solid var(--Red, #FF5050);
    background: #FFF;

    .all-text {
      padding: 10px;
    }

    h2 {
      color: red;

    }

    h6 {
      font-size: 10px;
    }

    .paragraph {
      color: var(--Black, #000);
      font-size: 16px;
      font-style: normal;
      line-height: 20px; /* 125% */
    }
  }

  .p-10 {
    padding: 10px;
  }

  .info_card {
    margin-bottom: 40px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .contact_flex { 
    display: flex;

    div {
      margin: 0 0 30px 0;
    }

    .contact_description {
      flex-basis: 80px;
	    flex-grow: 0;
	    flex-shrink: 0;
    }

    .contact_info {
      /* flex-basis: 300px; */
	    flex-grow: 0;
	    flex-shrink: 1;
    }

    &.contact-wrap {
        flex-wrap: wrap;
    }
  }

  .contact_label {
    width: 70px;
  }

  .cybercrime-image {
    background: url('./assets/cybercrime.png') lightgray 50% / cover no-repeat;
    width: 336px;
    height: 336px;
    flex-shrink: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      h1 {
        text-align: left;
        font-size: 32px;
      }

      .contact_details {
        .contact-wrapper {
          padding: 40px 30px;

          .contact_block {
            .contact_info {
            /* margin: 0 0 0 15px;
            overflow-wrap: anywhere; */

            a {
              font-size: 20px;
              line-height: 24px; /* 120% */
            }
          
            .note {
              font-size: 16px;
              line-height: 24px; /* 150% */
            }
          }
        }
      }
    }

    h2 {
      &.heading-6 {
      font-size: 20px;
      line-height: 24px; /* 120% */
      }

      font-size: 24px;
      line-height: 24px; /* 100% */
      margin-bottom: 20px;
    }

    .heading-6,
    p {
      font-size: 20px;
      line-height: 24px; /* 120% */
    }

    .contact_flex { 
      .contact_description {
        flex-basis: 250px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    
      .contact_info {
        /* flex-basis: 300px; */
        flex-grow: 0;
        flex-shrink: 1;
      }

      &.contact-wrap {
        flex-wrap: nowrap;
      }
  }
}
`

const Contact = () => {
  const [authenticationConfirmed, setAuthenticationConfirmed] = useState(false)
  const [clientRefiInfo, setClientRefiInfo] = useState('')

  const { loan } = useApiContext()
  const { loading: loanLoading, data: loanData } = loan

  const { authStatus } = useAuthenticator(context => [context.authStatus, context.user]);

  useEffect(() => {
    if (authenticationConfirmed) {
      const servicer = extractServicer(loanData)
      let clientRefiInfo = servicer && servicer.refiContactDetails

      setClientRefiInfo(clientRefiInfo)
    }
  }, [authenticationConfirmed, loanData])

  const updateAuthenticatedStatus = async () => setAuthenticationConfirmed(!!(await Auth.currentUserInfo()))

  if (authStatus === 'authenticated')
    updateAuthenticatedStatus()

  const { isSmall, isMedium } = useWindowSize()

  return (
    <>
      <Title>Contact Us</Title>
      <StyleGuide>
        <StyledContactModern>
          <div className='background-image'></div>
          <Grid className="grid">
            <div data-gridcolumnlayout={{
              sm: '1/14',
              md: '1/14',
              lg: '1/10',
              xl: '2/10',
            }}
              className="contact_details">
              {(isSmall || isMedium) && <NoticeOfErrorCard />}
              <h1
                data-gridcolumnlayout={{
                  sm: '2/8',
                  md: '2/14',
                  lg: '2/14',
                  xl: '3/14',
                }}>
                Contact Reverse Mortgage Servicing Department
              </h1>
              <div className="contact-wrapper">
                <div className="contact_block">
                  <div className="contact_flex">
                    <div className="contact_description">
                      <h2 className="heading-6">By Email:</h2>
                    </div>
                    <div className="contact_info">
                      <a
                        href="mailto:BC@reversedepartment.com"
                      >
                        BC@reversedepartment.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="contact_block">
                  <div className="contact_flex">
                    <div className="contact_description">
                      <h2 className="heading-6">By Fax:</h2>
                    </div>
                    <div className="contact_info">
                      <a
                        href="tel:+18666162160"
                        aria-label="By fax. Toll free. 1. 866. 616. 2160."
                      >
                        (866) 616–2160
                      </a>
                    </div>
                  </div>
                </div>
                <div className="contact_block">
                  <div className="contact_flex">
                    <div className="contact_description">
                      <h2 className="heading-6">By Mail:</h2>
                    </div>
                    <div className="contact_info">
                      <p>
                        P.O. Box 40724 <br />
                        Lansing MI 48901-7924
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-wrapper">
                <h2>Borrower Care</h2>
                <div className="contact_block">
                  <div className="contact_flex">
                    <div className="contact_description">
                      <h2 className="heading-6">Phone:</h2>
                    </div>
                    <div className="contact_info">
                      <a
                        href="tel:+18666540020"
                        aria-label="Borrower Care. By phone. 1. 866. 654. 0020."
                      >
                        (866) 654–0020
                      </a>
                      <p style={{ "marginTop": "10px" }}>
                        If your loan is in Default and your property is in
                        NY, please call us at{' '}
                        <a
                          className="subtitle bold"
                          href="tel:+18665421131"
                          aria-label="If your loan is in Default and your property is in NY, please call Borrower Care. By phone. 1. 866. 542. 1131."
                        >
                          (866) 542-1131
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact_block">
                  <div className="contact_flex">
                    <div className="contact_description"><h2 className="heading-6">Hours:</h2></div>
                    <div className="contact_info">
                      <p>
                        Monday - Thursday 8:00 AM - 7:00 PM ET,
                      </p>
                      <p>
                        Friday 8:00 AM - 5:00 PM ET
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-wrapper">
                <h2>Tax Department</h2>
                <div className="contact_block">
                  <div className="contact_info">
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Phone:</h2>
                      </div>
                      <div className="contact_info">
                        <a
                          href="tel:+18888253802"
                          aria-label="Tax. Department. By phone. 1. 888. 825. 3802."
                        >
                          (888) 825-3802
                        </a>
                      </div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Hours:</h2>
                      </div>
                      <div className="contact_info">
                        <p>
                          Monday-Thursday 8:00 AM - 8:00 PM ET
                        </p>
                        <p>
                          Friday 8:00 AM - 5:00 PM ET
                        </p>
                        <p>To submit a paid tax receipt, add your loan number and please send to one of the following: </p>
                      </div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Fax:</h2>
                      </div>
                      <div className="contact_info"><a
                        className="subtitle bold"
                        href="tel:+18554931943"
                        aria-label="Tax. Department. By fax. 1. 855. 493. 1943."
                      >
                        (855) 493-1943
                      </a></div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Mail:</h2>
                      </div>
                      <div className="contact_info">
                        <p>Tax Department</p>
                        <p>PO Box 35605</p>
                        <p>Dallas, TX  75235-0605</p>
                      </div>
                    </div>
                    <div className="contact_flex contact-wrap">
                      <div className="contact_description">
                        <h2 className="heading-6">Email:</h2>
                      </div>
                      <div className="contact_info"><a
                        className="subtitle bold"
                        href="mailto:Property.TaxDocs@reversedepartment.com"
                        aria-label="Tax. Department. By email. Property.TaxDocs@reversedepartment.com."
                      >
                        Property.TaxDocs@reversedepartment.com
                      </a>
                        <p className="note">*Please note this email address is only to send documents.  Any questions should be directed to the phone number above. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-wrapper">
                <h2>Insurance Department</h2>
                <div className="contact_block">
                  <div className="contact_info">
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Phone:</h2>
                      </div>
                      <div className="contact_info">
                        <a
                          className="subtitle bold"
                          href="tel:+18668712412"
                          aria-label="Insurance. Department. By phone. 1. 866. 871. 2412."
                        >
                          (866) 871-2412
                        </a>
                      </div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Hours:</h2>
                      </div>
                      <div className="contact_info">
                        <p> Monday – Friday 8:30AM-7:00PM ET</p>
                        <p>To submit your insurance policy, add your loan number and please send to one of the following:</p>
                      </div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Fax:</h2>
                      </div>
                      <div className="contact_info"><a
                        className="subtitle bold"
                        href="tel:+14405052000"
                        aria-label="Insurance. Department. By fax. 1. 440. 505. 2000."
                      >
                        (440) 505-2000
                      </a></div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Mail:</h2>
                      </div>
                      <div className="contact_info">
                        <p>Insurance Department</p>
                        <p>PO Box 39457</p>
                        <p>Solon, OH  44139-0457</p>
                      </div>
                    </div>
                    <div className="contact_flex contact-wrap">
                      <div className="contact_description">
                        <h2 className="heading-6">Online:</h2>
                      </div>
                      <div className="contact_info">
                        <a className="subtitle bold"
                          href="https://www.expressinsuranceinfo.com/3142530 "
                          aria-label="Insurance. Department. Online. https://www.expressinsuranceinfo.com/3142530."
                        >
                          https://www.expressinsuranceinfo.com/3142530&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} className="external_icon" aria-label='link opens in new window' />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-wrapper">
                <h2>Loss Draft (Insurance Claims) Department</h2>
                <div className="contact_block">
                  <div className="contact_info">
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Phone:</h2>
                      </div>
                      <div className="contact_info">
                        <a
                          className="subtitle bold"
                          href="tel:+18002666337"
                          aria-label="Loss. Draft. Insurance. Claims. By phone. 1. 800. 266. 6337."
                        >
                          (800) 266-6337
                        </a>
                      </div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Hours:</h2>
                      </div>
                      <div className="contact_info">
                        <p>Monday-Friday 8:00 AM - 6:00 PM ET</p>
                        <p>To submit claim documentation, add your loan number and please send to one of the following:</p>
                      </div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Fax:</h2>
                      </div>
                      <div className="contact_info"><a
                        className="subtitle bold"
                        href="tel:+12487101690"
                        aria-label="Loss. Draft. Insurance. Claims. By fax. 1. 248. 710. 1690."
                      >
                        (248) 710-1690
                      </a></div>
                    </div>
                    <div className="contact_flex">
                      <div className="contact_description">
                        <h2 className="heading-6">Mail:</h2>
                      </div>
                      <div className="contact_info">
                        <p>Loss Draft Department</p>
                        <p>PO Box 39608</p>
                        <p>Solon, OH  44139-0457</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isSmall && <CybercrimeWarningDesktop />}
            </div>
            <div data-gridcolumnlayout={{
              sm: '1/14',
              md: '1/14',
              lg: '1/14',
              xl: '10/13',
            }}>
              {isSmall && <CyberCrimeWarning />}
              {!isSmall && !isMedium && (<NoticeOfErrorCard />)}
              {authenticationConfirmed && (
                <InfoCard
                  loading={
                    authenticationConfirmed
                      ? loanLoading
                      : false
                  }
                  smallScreenStyles
                  data-gridcolumnlayout={{
                    sm: '2/8',
                    md: '9/12',
                    lg: '9/12',
                    xl: '10/13',
                  }}
                  title="Thinking of refinancing?"
                  SVG={PiggyBankIcon}
                  paragraph={
                    authenticationConfirmed &&
                      isValidPhoneNumber(clientRefiInfo) ? (
                      <>
                        If you are interested in refinancing your reverse
                        mortgage loan, please call us at{' '}
                        <a
                          className="caption phone_web_link"
                          href={`tel:+1-${formatPhoneNumber(
                            clientRefiInfo,
                          ).replaceAll(/\s/g, '')}`}
                          aria-label={`If you are interested in refinancing your reverse mortgage loan, please call us at. ${formatPhoneNumber(clientRefiInfo).replaceAll(/\s/g, '')}`}
                        >
                          {formatPhoneNumber(clientRefiInfo)}
                        </a>{' '}
                        to learn more. Refer to code PORTAL when you call.
                      </>
                    ) : (
                      <>
                        If you are interested in refinancing your reverse
                        mortgage loan, please visit{' '}
                        <a
                          className="caption phone_web_link"
                          href="https://www.reversemortgage.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.reversemortgage.org
                        </a>{' '}
                        to learn more.
                      </>
                    )
                  }
                />
              )}
            </div>
          </Grid>
        </StyledContactModern>
      </StyleGuide>
    </>
  )
}

export default Contact
