import { useState, useEffect } from 'react'

import { theme } from '../Global/Theme'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  let isMobile =
    windowSize.width <= parseInt(theme.breakPoints.mobile, 10)

  let isXSmall =
    windowSize.width <= parseInt(theme.breakPoints.xSmall, 10)

  let isSmall =
    windowSize.width <= parseInt(theme.breakPoints.small, 10)

  let isMedium =
    windowSize.width <= parseInt(theme.breakPoints.medium, 10)

  let isLarge =
    windowSize.width >= parseInt(theme.breakPoints.medium, 10)

  return { windowSize, isMobile, isXSmall, isSmall, isMedium, isLarge }
}

export default useWindowSize
