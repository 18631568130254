import React,{useState} from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Button from '../../Common/button'
import VideoIcon from '../../assets/video-icon'
import RegistrationModal from '../../Common/RegistrationModal'
import RegistrationHelpIcon from '../../assets/registration-help-icon'
const StyledHelpModal = styled(Modal)`
  .modal-content{
    padding:20px;
    border-radius:10px;
  }
  .helpcard{
    background: #FFFFFF;
    height:100%;
  }
  .help1{
    display:flex;
    column-gap:5px;
  }
   .text {
    display: inline-flex;

    button {
      text-transform: none;
    }
  }
`
const CloseButton = styled.button`
    color:#475468;
    border:1px solid #475468;
    padding:10px;
    border-radius:10px; 
    background:white;
    width:100%;
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;


  `;

const NeedHelpModal = ({ setHelpModal }) => {
  const [modalShow, setModalShow] = useState(false)
  return (
    <>
    <RegistrationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        page="registration"
      />
    <StyledHelpModal
      onHide={() => setHelpModal(false)}
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
       <div className='helpcard'>
          <div className='help1'>
            <VideoIcon />
              <div>
                <p>Need help Registering?</p>
                <p>Watch our registration video  <span className='text'><Button
                  text="here"
                  UID="registration_video"
                  textButton
                  ariaLabel="Click here to watch our registration video"
                  onClick={()=>{setModalShow(true)}}
                  /></span>
                </p>
               </div>
          </div>       

          <div className='help1' style={{marginTop:'50px'}}>
            <RegistrationHelpIcon />
                 <div>
                    <p>Need Help?</p>
                    <b>Helpful Tips:</b><br />
                    <ul className='helpful-tips'>
                      <li>Make sure you are using the most current version of your preferred browser (Chrome, Edge, Safari, etc.- this site is not compatible with Internet Explorer)</li>
                      <li>You may need to clear your browser’s cache and cookies.</li>
                      <li>We recommend that you do not use “auto-fill” when creating an account.</li>
                    </ul>
                 </div>
          </div>    

        </div>
      <div className="buttons">
        <CloseButton
          onClick={() => setHelpModal(false)}
        >
            Close
        </CloseButton>
      </div>
    </StyledHelpModal>
    </>
  )
}

export default NeedHelpModal
