import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
    stroke: ${({ theme, color }) => theme.colors[color]};
  }
`

const SuccessVerificationIcon = ({ color = 'primaryDark' }) => {
    return (
        <StyledSVG width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.507 59.75L17.1237 50.5417L6.42786 48.3458L7.63203 37.9333L0.832031 30L7.63203 22.1375L6.42786 11.725L17.1237 9.52917L22.507 0.25L31.9987 4.64167L41.4904 0.25L46.9445 9.52917L57.5695 11.725L56.3654 22.1375L63.1654 30L56.3654 37.9333L57.5695 48.3458L46.9445 50.5417L41.4904 59.75L31.9987 55.3583L22.507 59.75ZM24.4195 54.1542L31.9987 50.9667L39.7904 54.1542L44.5362 47.0708L52.8237 44.9458L51.9737 36.5167L57.7112 30L51.9737 23.3417L52.8237 14.9125L44.5362 12.9292L39.6487 5.84583L31.9987 9.03333L24.207 5.84583L19.4612 12.9292L11.1737 14.9125L12.0237 23.3417L6.2862 30L12.0237 36.5167L11.1737 45.0875L19.4612 47.0708L24.4195 54.1542ZM28.9529 39.4208L45.032 23.4833L41.8445 20.5792L28.9529 33.3292L22.2237 26.3167L18.9654 29.5042L28.9529 39.4208Z" fill="#0ACD06"/>
        </StyledSVG>
    )
}

export default SuccessVerificationIcon
